



































































import { Component, Watch } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  ExamsCreatorsReportQuery, ExamsCreatorsReportFilters, BasicCreator, BasicCourse, ServerTranslation,
} from '@/types/reports.type';
import { ScopeClass, Lang } from '@/types/general.type';
import ScopeComponent from '@/components/scope.component.vue';
import UTILS from '@/shared/utils';
import CourseElectronicExams from '@/components/courses/CourseElectronicExams.component.vue';
import { APPIDS } from '../../enums';
import ExamsCreatorsReportFiltersComponent from '../filters/ExamsCreatorsReportFilters.component.vue';
import Toaster from '../../mixins/toaster.mixin';
import { ElectronicExamsReportResponse } from '../../types/course.type';

@Component({
  components: {
    ScopeComponent,
    ExamsCreatorsReportFiltersComponent,
    CourseElectronicExams,
  },
})
export default class ExamsCreatorsReport extends mixins(Toaster) {
  appId: string = APPIDS.EXAMS_CREATORS_REPORT;

  courseId = '';

  courseName: ServerTranslation = {};

  creatorId = '';

  creatorName: ServerTranslation = {};

  componentKey = 0;

  loading = false;

  showReport = false;

  showPrint = false;

  callReport = false;

  currentLang = this.$store.state.lang.lang;

  scopeActive = false;

  scopeComponentRef: ScopeClass = ScopeComponent;

  response: ElectronicExamsReportResponse | null = null;

  examCreators: BasicCreator[] = [];

  filtersInputs: ExamsCreatorsReportFilters = {
    scopeId: this.$store.state.scope.selectedScopeId,
  };

  mounted() {
    this.fetchFiltersData();
  }

  @Watch('$store.state.lang.lang')
  onLangChange(newVal) {
    this.currentLang = newVal;
  }

  fetchFiltersData() {
    Object.keys(this.filtersInputs).forEach((key: string) => {
      if (this.$route.query[key]) {
        this.filtersInputs[key] = this.$route.query[key];
      }
    });
    if (!this.filtersInputs.scopeId || (this.filtersInputs.scopeId.split('.').length < 3)) {
      this.scopeActive = false;
    } else {
      this.scopeActive = true;
    }
  }

  async scopeChange(selectedScopeId) {
    if (!selectedScopeId || (selectedScopeId.split('.').length < 3)) {
      this.scopeActive = false;
    } else {
      this.scopeActive = true;
    }
    this.filtersInputs.scopeId = selectedScopeId;
    this.componentKey += 1;
    if (selectedScopeId) {
      this.filtersInputs.creators = '';
      this.filtersInputs.courses = '';
      this.$forceUpdate();
      this.showReport = false;
      this.response = null;
    }
  }

  search() {
    if (!this.filtersInputs.scopeId || (this.filtersInputs.scopeId.split('.').length < 3)) {
      this.$store.commit('openScope');
      this.scopeComponentRef.setFocus();
      return;
    }
    this.getReportData();
  }

  resetSearch() {
    Object.keys(this.filtersInputs).forEach((key: string) => {
      this.filtersInputs[key] = '';
    });
    this.resetScope();
    this.showReport = false;
    this.response = null;
  }

  resetScope() {
    this.$store.commit('resetScope');
  }

  async getReportData() {
    try {
      this.callReport = true;

      const creatorInput = this.filtersInputs.creators || {};
      const creatorObj: BasicCreator = creatorInput;
      this.creatorName = creatorObj.Name || {};

      const courseInput = this.filtersInputs.courses || {};
      const courseObj: BasicCourse = courseInput;
      this.courseName = courseObj.Name || {};

      const reqQuery: ExamsCreatorsReportQuery = {
        scopeId: this.filtersInputs.scopeId,
        creators: creatorObj.Id,
        courses: courseObj.Id,
      };

      this.courseId = reqQuery.courses || '';
      this.creatorId = reqQuery.creators || '';

      if (this.currentLang === 'en') { reqQuery.langIsEn = true; }
    } catch (err) {
      this.loading = false;
      this.errorToaster(err as Lang, { duration: 5000 });
    } finally {
      this.showReport = true;
    }
  }

  openAllChildPanels() {
    (this.$refs.childComponentRef as Vue).$emit('open-all-panels');

    setTimeout(() => {
      const printElmHtml = document.querySelector('#ExamsReportTables')?.innerHTML;
      UTILS.PRINT_REPORT(printElmHtml, this.$store.state.lang.lang);
    }, 500);
  }

  resetFlag() {
    this.callReport = false;
  }

  updateShowPrint(print) {
    this.showPrint = print;
  }
}
