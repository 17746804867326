

































































import {
  Component, Vue, Prop, Provide,
} from 'vue-property-decorator';
import {
  ExamsCreatorsReportFilters, ExamsCreatorsReportFiltersQuery, BasicCreator, BasicCourse,
} from '@/types/reports.type';
import ReportProvider from '@/providers/reports.provider';
import servertranslate from '@/filters/server-translate';
import { APPIDS } from '../../enums';

@Component
export default class ExamsCreatorsReportFiltersComponent extends Vue {
  @Prop() readonly filtersInputs!: ExamsCreatorsReportFilters;

  @Prop() readonly scopeActive;

  @Provide() Provider = ReportProvider;

  appId: string = APPIDS.EXAMS_CREATORS_REPORT;

  currentLang = this.$store.state.lang.lang;

  loading = true;

  examCreators: BasicCreator[] = [];

  examCourses: BasicCourse[] = [];

  $refs!: {
    input: HTMLFormElement;
  };

  async mounted() {
    this.fetchFiltersData();
  }

  async selectedMatchCourses() {
    try {
      this.loading = true;
      this.$refs.input.reset();
      const creatorInput = this.filtersInputs.creators || {};
      const creatorObj: BasicCreator = creatorInput;

      const reqQuery: ExamsCreatorsReportFiltersQuery = {
        scopeId: this.filtersInputs.scopeId,
        creators: creatorObj.Id,
      };

      if (reqQuery.scopeId && reqQuery.creators) {
        const courses: BasicCourse[] = await ReportProvider.getCreatorsCoursesReportFilters(
          this.appId, reqQuery,
        );

        if (courses) {
          this.examCourses = courses;
          this.loading = false;
        }
      } else {
        this.examCourses = [];
        this.filtersInputs.courses = '';
      }
    } catch (err) {
      this.loading = false;
    }
  }

  async fetchFiltersData() {
    try {
      this.loading = true;
      const reqQuery: ExamsCreatorsReportFiltersQuery = {
        ...this.filtersInputs,
      };

      if (reqQuery.scopeId) {
        const creators: BasicCreator[] = await ReportProvider.getExamsCreatorsReportFilters(
          this.appId, reqQuery,
        );

        if (creators) {
          this.examCreators = creators;
          this.loading = false;
        }
      }
    } catch (err) {
      this.loading = false;
    }
  }

  get filtersCreators() {
    return {
      creators: this.examCreators?.map((creator: BasicCreator) => ({
        ...creator,
        id: creator.Id,
        translatedName: this.$options.filters?.servertranslate(
          creator.Name
        ),
      })),
    };
  }

  get filtersCourses() {
    return {
      courses: this.examCourses?.map((course: BasicCourse) => ({
        ...course,
        id: course.Id,
        translatedName: this.$options.filters?.servertranslate(
          course.Name
        ),
        translatedStudyPlanName: servertranslate(
          course.studyPlanName,
        ),
      })),
    };
  }
}

